import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import EditarListaDifusion from "./EditarListaDifusion";
import moment from "moment";
import EditarListaContactos from "./EditarListaContactos";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
  tableRow: {
    backgroundColor: "#af272f",
  },
  alturaTabla: {
    minHeight: "420px",
    maxHeight: "86vh",
    overflowY: "auto",
  },
  tableCellHeader: {
    witdh: "auto",
    backgroundColor: "#af272f",
    color: "white",
  },
  tableCellSeleccionada: {
    color: "white !important",
  },
}));

const columnas = [
  {
    id: 2,
    titulo: "Fecha",
  },
  {
    id: 3,
    titulo: "Nombre",
  },
  {
    id: 4,
    titulo: "Usuario",
  },
  {
    id: 5,
    titulo: "Estatus",
  },
];

export default function TablaListaDifusion({
  difusionList,
  setDifusionDetail,
  history,
  UserId,
  DeviceId,
  setSelectedList,
  botonAgregar,
  difusionDetail,actualizar
}) {
  const classes = useStyles();

  const [idBotonEditar, setIdBotonEditar] = useState(-1);
  const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
  const [modalEditarContactosAbierto, setModalEditarContactosAbierto] =
    useState(false);
  const [modalTitulo, setModalTitulo] = useState("");
  const [sinDatos, guardarSinDatos] = useState(true);
  const [espera, setEspera] = useState(false);

  const [difusionListSelected, setDifusionListSelected] = useState(null);

  const modalEditarListaDifusion = modalEditarAbierto ? (
    <EditarListaDifusion
      modalAbierto={modalEditarAbierto}
      setModalAbierto={setModalEditarAbierto}
      titulo={modalTitulo}
      UserId={UserId}
      DeviceId={DeviceId}
      history={history}
      difusionListSelected={difusionListSelected}
      difusionList={difusionList}
    />
  ) : null;

  const modalEditarListaContactos = modalEditarContactosAbierto ? (
    <EditarListaContactos
      modalAbierto={modalEditarContactosAbierto}
      setModalAbierto={setModalEditarContactosAbierto}
      titulo={modalTitulo}
      UserId={UserId}
      DeviceId={DeviceId}
      history={history}
      difusionListSelected={difusionListSelected}
      difusionDetail={difusionDetail}
      setDifusionDetail={setDifusionDetail}
      actualizar={actualizar}
    />
  ) : null;

  function abrirModalEdit(row) {
    setModalEditarAbierto(!modalEditarAbierto);
    setModalTitulo("Editar " + row.Nom);
    setDifusionListSelected(row);
  }

  function abrirModalEditarContactos(row) {
    setModalEditarContactosAbierto(!modalEditarContactosAbierto);
    setModalTitulo("Editar contactos de " + row.Nom);
    setDifusionListSelected(row);
  }

  function handleClickEditar(row) {
    /* if (row.Id !== idBotonEditar) {
      setIdBotonEditar(row.Id);
      setSelectedList(row.Id);
    } */
    setIdBotonEditar(row.Id);
      setSelectedList(row.Id);
  }
  const celdaSel = (alinear, estilo, contenido,claseCelda ,index) => {
    return (
      <TableCell
        key={index} align={alinear} className={claseCelda}
        padding={"none"} style={estilo}
      >
        {contenido}
      </TableCell>
    );
  };

  function tablaCompleta(auxlista) {
    return (
      <TableContainer className={classes.alturaTabla}>
        <Table
          className={classes.table}
          size="small"
          aria-label="resultados"
          stickyHeader
        >
          <TableHead>
            <TableRow>              
              {celdaSel("center", [], botonAgregar,classes.tableCellHeader,0)}
              {celdaSel("center", [], "",classes.tableCellHeader,1)}
              {columnas.map((row) => {
                return (                  
                  celdaSel("center", [], row.titulo,classes.tableCellHeader,row.id)
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {auxlista.map((row, index) => {
              let claseCelda =row.Id === idBotonEditar ? classes.tableCellSeleccionada:classes.tableCell
              let auxFec=moment.utc(row.Fecha).format("DD/MM/YYYY")
              return(
              <TableRow
                key={index} onClick={() => handleClickEditar(row)}
                className={row.Id === idBotonEditar ? classes.tableRow : ""}
              >
                <TableCell
                  align="center" padding={"none"} className={classes.TableCell}
                >
                  {row.Id === idBotonEditar ? (
                    <Tooltip title={`Editar Lista de Difusión`}>
                      <IconButton size="small"  component={"span"}
                        onClick={
                          row.Id === idBotonEditar
                            ? () => abrirModalEdit(row)
                            : null
                        }
                      >
                        <EditIcon style={{ color: "white" }} size="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell
                  align="center" padding={"none"} className={classes.TableCell}
                >
                  {row.Id === idBotonEditar ? (
                    <Tooltip title={`Editar Lista de Contactos`}>
                      <IconButton
                        size="small"
                        component={"span"}
                        onClick={
                          row.Id === idBotonEditar
                            ? () => abrirModalEditarContactos(row)
                            : null
                        }
                      >
                        <ListIcon style={{ color: "white" }} size="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </TableCell>                      
                {celdaSel("center",{ width: 40 + "px" }, auxFec,claseCelda,index)}
                {celdaSel("left",{ paddingLeft: 15, paddingRight: 25 }, row.Nom,claseCelda,index)}
                {celdaSel("left", [], row.UsrNom,claseCelda,index)}
                <TableCell
                  align="center" padding={"none"}
                  style={{ width: "5rem", paddingRight: ".5rem", paddingLeft: ".5rem", }}
                  className={claseCelda}
                >
                  <Card
                    style={{ color: "white",
                      backgroundColor: row.Status === 1 ? "green" : "orange",
                    }}
                  >
                    <span>{row.Status === 1 ? "Activo" : "Inactivo"}</span>
                  </Card>
                </TableCell>
              </TableRow>)
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const tablaSinDatos = () => {
    return (
      <TableContainer>
        <Table
          className={classes.table}
          size="small"
          aria-label="resultados"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columnas.map((row) => (
                <TableCell
                  key={row.id}
                  padding={"none"}
                  align="center"
                  className={classes.tableCellHeader}
                >
                  {row.titulo}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" padding={"none"} style={{ width: 95 }}>
                Sin
              </TableCell>
              <TableCell align="center" padding={"none"} style={{ width: 95 }}>
                datos
              </TableCell>
              <TableCell
                align="center"
                padding={"none"}
                style={{ width: 95 }}
              ></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const contenido =
    sinDatos && difusionList.length !== 0
      ? tablaCompleta(difusionList)
      : tablaSinDatos();
  const tabla = espera ? (
    <Box mt={2}>
      <LinearProgress />
    </Box>
  ) : (
    contenido
  );

  return (
    <Paper className={classes.root} style={{ witdh: 40 + "rem" }}>
      {tabla}
      {modalEditarListaDifusion}
      {modalEditarListaContactos}
    </Paper>
  );
}
