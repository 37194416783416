import { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    a: {
      color: "black !important",
      textDecoration: "inherit !important",
    },
  },
  chatRoom: {
    display: "flex",
    paddingLeft: "1rem",
    cursor: "pointer",
    borderBottom: "1px solid #f6f6f6",
    "&:hover": {
      backgroundColor: "#ebebeb",
    },
    marginTop:.2+'rem'
  },
  chatInfo: {
    color:"black",
    marginLeft: "15px",
    "& h2": {
      fontSize: "16px",
      
      marginBottom: ".2rem",
    },
  },
}));

const SidebarChats = ({
  addNewChat,
  contact,
  id,
  setShowChat,
  setShowSidebar,
  viewPort,setNumChat,listUltimoMsg
}) => {
  const classes = useStyles();

  const [lastMessage, setLastMessage] = useState([{ message: "" }]);

  useEffect(() => {
    if (id) {
      //Peticion para recuperar los mensajes de un chat en base al ID setLastMessage
      setLastMessage([{ message: "Hola" }]);
    }
  }, [id]);

  const createChat = () => {
    const roomName = prompt("Ingrese el nombre del chat");
    if (roomName) {
      //Hace la insercion de un nuevo chat room en la bd
    }
  };

  const handleShow = () => {
    //console.log(contact.id.substring(0,13));
    setNumChat({telefono:contact.id.substring(0,13),original:contact})
    if (viewPort <= 768) {
      setShowChat(true);
      setShowSidebar(false);
    } else {
      return;
    }
    
  };

  return !addNewChat ? (    
      <div className={classes.chatRoom} onClick={handleShow}>
        <Avatar src={contact.photoUrl?contact.photoUrl:""} />
        <div className={classes.chatInfo}>
          {/* <h2>{contact.nameBd?contact.nameBd: contact.name}</h2> */}
          <p style={{fontSize:10+'px',color:"#af272f",fontWeight:700}}>{contact?.infoEstruc}</p>
          <h5 style={{fontSize:12+'px'}}>{contact.nameBd?contact.nameBd: contact.name }</h5>          
          <p style={{fontSize:12+'px'}}>{contact?.ultimoMsg }</p>
        </div>
      </div>    
  ) : (
    <span>{null}
    {/* <div onClick={createChat} className={classes.chatRoom}>
      <h2>Nuevo Mensaje</h2>
    </div> */}
    </span>
  );
};

export default SidebarChats;
