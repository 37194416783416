import axios from "axios";
const source = axios.CancelToken.source();
export async function llamadaApi(data, url, miFuncion,funcionError,historial) {
    let token =localStorage.getItem('token20') ;         
    let config = {
      method: "post",
      url: process.env.REACT_APP_LINK + url,
      headers: { 'access-token' : token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);           
      })
      .catch(function (error) {
        funcionError(error,historial)
      });
}

export async function llamadaApiCarga(data, url, miFuncion,funcionError,setEspera,historial) {
    let token =localStorage.getItem('token20') ;     
    setEspera(true)
    let config = {
      method: "post",
      url: process.env.REACT_APP_LINK + url,
      headers: { 'access-token' : token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
      cancelToken: source.token,
    };
    await axios(config)
      .then(function (response) {
        let auxiliar = response.data;
        miFuncion(auxiliar);           
      })
      .catch(function (error) {
        console.log(error);
        funcionError(error,historial)
      });
    setEspera(false)
}

export async function llamadaApiExt(data, url, miFuncion,funcionError,setEspera,historial) {
  let token =localStorage.getItem('token20') ;     
  setEspera(true)
  let config = {
    method: "post",
    url: process.env.REACT_APP_RV + url,
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: source.token,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial)
    });
  setEspera(false)
}

export async function apiExtCargaToken(data, url, miFuncion,funcionError,setEspera,historial,toknCancela) {
  let token =localStorage.getItem('token20') ;     
  setEspera(true)
  let config = {
    method: "post",
    url: process.env.REACT_APP_RV + url,
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: toknCancela.token,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial)
    });
  setEspera(false)
}

export async function llamaApiCargaToken(data, url, miFuncion,funcionError,setEspera,historial,toknCancela) {
  let token =localStorage.getItem('token20') ;     
  setEspera(true)
  let config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: toknCancela.token,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial)
    });
  setEspera(false)
}

export async function llamadaApiToken(data, url, miFuncion,funcionError,historial,toknCancela) {
  let token =localStorage.getItem('token20') ;         
  let config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
    cancelToken: toknCancela,
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      funcionError(error,historial)
    });
}
export async function llamaApiFormData(data, url, miFuncion,funcionError,setEspera,historial,toknCancela,auxSize,setProgresoEnvio) {
  let token =localStorage.getItem('token20') ;     
  setEspera(true)
  let config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,'size':auxSize,
      "Content-Type": "multipart/form-data",
    },
    data: data,
    cancelToken: toknCancela.token,
    onUploadProgress:data => {
      let progreso= ((100 * data.loaded) / data.total).toFixed(2)
      setProgresoEnvio(progreso)
    },
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial)
    });
  setEspera(false)
}

export async function llamaApiFormData2(data, url, miFuncion,funcionError,setEspera,historial,toknCancela,auxSize,setProgresoEnvio,auxTipo,auxCarpeta) {
  let token =localStorage.getItem('token20') ;     
  setEspera(true)
  let config = {
    method: "post",
    url: process.env.REACT_APP_LINK + url,
    headers: { 'access-token' : token,'size':auxSize,
      "Content-Type": "multipart/form-data",
      'tipo':auxTipo, 'carpeta':auxCarpeta
    },
    data: data,
    cancelToken: toknCancela.token,
    onUploadProgress:data => {
      let progreso= ((100 * data.loaded) / data.total).toFixed(2)
      setProgresoEnvio(progreso)
    },
  };
  await axios(config)
    .then(function (response) {
      let auxiliar = response.data;
      miFuncion(auxiliar);           
    })
    .catch(function (error) {
      console.log(error);
      funcionError(error,historial)
    });
  setEspera(false)
}

export function ErrorGeneral(error,historial) {
  console.log(error);            
  if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
  { console.log('datos incorrectos' );  
  }
  else
  {
    if ( ( error && error.response && error.response.data && error.response.data?.error?.name==="JsonWebTokenError" )||
        ( error && error.response && error.response.data && error.response.data?.error?.name==="TokenExpiredError" ) ) 
    {
      console.log('loguearse de nuevo');
      localStorage.clear();                            
      historial.push("/");                              
    }
    else{
      if ( ( error && error.response && error.response.data && error.response.data.mensaje==="No se agrego el Token en los Headers de la petición." )) 
      {
        console.log('loguearse de nuevo');
        localStorage.clear();                            
        historial.push("/");                              
      }
    } 
  }      
}


export async function validaURL(url) {
  await axios.get(url).then((response) => {
    console.log(response)
  }).catch((error) => {
      console.log(error)
  })
}